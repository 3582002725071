import React from "react";
import AuthLayout from "../layout/AuthLayout";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "../pages/auth/Login";
import ResetPassword from "../pages/auth/ResetPassword";
import DashboardLayout from "../layout/DashboardLayout";
import Dashboard from "../pages/app/Dashboard";
import { RequiredAuth } from "../hooks/RequiredAuth";
import TransactionHistory from "../pages/app/TransactionHistory";

const RouteSwitch = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/auth" element={<AuthLayout />}>
          <Route path="/auth/login" element={<Login />} />
          <Route path="/auth/reset-password" element={<ResetPassword />} />
        </Route>
        <Route element={<RequiredAuth />}>
          <Route path="/" element={<DashboardLayout />}>
            <Route index element={<Dashboard />} />
            <Route path="/transactions" element={<TransactionHistory />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default RouteSwitch;
