import React from "react";
import Logo from "../assets/svgs/logo.svg";
import { Outlet } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { carouselDetails } from "../utils/helper";
import TestimonialCarousel from "../components/TestimonialCarousel";
// import { Toaster } from "react-hot-toast";

const AuthLayout = () => {
  return (
    <div className={`auth`}>
      <div className="auth__aside">
        <div className="auth__aside--content">
          <div className="brand">
            <img src={Logo} alt="Flance Logo" />
          </div>
          <div className="auth__aside--header">
            <h1>Oversee your gym's memberships with ease</h1>
            <p>
              Track member bookings and activities seamlessly. Access valuable
              insights for customizing your services effectively.
            </p>
          </div>
          <div className="auth__aside--images">
            <Carousel
              showArrows={false}
              autoPlay={true}
              infiniteLoop={true}
              showStatus={false}
              showIndicators={true}
              swipeable={false}
              showThumbs={false}
            >
              {carouselDetails.map((details) => (
                <div key={details?.id}>
                  <TestimonialCarousel
                    altText={details?.altText}
                    companyLogo={details?.companyLogo}
                    companyRep={details?.name}
                    repTitle={details?.position}
                    testimonial={details?.testimonial}
                  />
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
      <div className="auth__form">
        {/* <div className="toaster__style">
          <Toaster position="top-right" reverseOrder={false} />
        </div> */}
        <Outlet />
      </div>
    </div>
  );
};

export default AuthLayout;
