import React from "react";
import Logo from "../assets/svgs/logo.svg";
import Icon from "./Icon";
import { navigations } from "../utils/navigations";
import { Link } from "react-router-dom";

const DashboardSIdebar = ({
  // active,
  // setActive,
  // setSlidingSidebar,
  // slidingSidebar,
  splitLocation,
}) => {
  console.log(splitLocation);
  return (
    <div className="sidebar--container">
      <div className={` ${"dashboard__sidenav"}`} role="navigation">
        <div className="dashboard__sidenav--banner">
          <img src={Logo} alt="fla" />
        </div>
        <ul>
          {navigations.map((navigation) => {
            return (
              <Link
                to={navigation.link}
                // onClick={() => setActive(navigation.link)}
                className={`navlinks ${
                  splitLocation[1] === navigation.navName ? "active" : ""
                } active`}
                key={navigation.id}
              >
                <Icon
                  width="16px"
                  height="16px"
                  id={`${
                    splitLocation[1] === navigation?.navName
                      ? navigation?.coloredIcon
                      : navigation?.icon
                  }`}
                />
                <p>{navigation?.name}</p>
              </Link>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default DashboardSIdebar;
