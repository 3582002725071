import React from "react";
import { dateFormat, fmtCurrency } from "../../utils/helper";
import Table from "./Table";

const TransactionsTable = ({ data }) => {
  const columns = ["Date", "description", "Amount", "Reference", "Status"];
  return (
    <Table columns={columns}>
      {data?.map((row, index) => (
        <tr className="pointer grey-bg" key={index}>
          <td> {dateFormat(row?.dateCreated, "MMM dd, yyyy")}</td>
          <td>{row?.transactionType}</td>
          <td>{fmtCurrency(row?.amount)}</td>
          <td>{row?.transactionId}</td>
          <td>{row?.operationType}</td>
        </tr>
      ))}
    </Table>
  );
};

export default TransactionsTable;
