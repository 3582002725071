// import { createSlice, createAsyncThunk } from "reduxjs/toolkit";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import customFetch, { checkForUnauthorizedResponse } from "../../utils/axios";
import toast from "react-hot-toast";
import {
  addItemToLocalStorage,
  addUserToLocalStorage,
  getUserFromLocalStorage,
  removeUserFromLocalStorage,
} from "../../utils/localstorage";

const initialState = {
  isLoading: false,
  user: getUserFromLocalStorage(),
  subscriberList: [],
  errMsg: null,
  changePassword: false,
  passwordChanged: false,
  providerCredit: null,
  transactions: [],
};
export const loginUser = createAsyncThunk(
  "user/loginUser",
  async (user, thunkAPI) => {
    try {
      const resp = await customFetch.post("auth/signin/provider", user);
      return resp?.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  }
);

export const getProviderSubscribers = createAsyncThunk(
  "user/getProviderSubscribers",
  async (_, thunkAPI) => {
    try {
      const resp = await customFetch.get("auth/provider/subscribers");
      return resp?.data;
    } catch (error) {
      return checkForUnauthorizedResponse(error, thunkAPI);
    }
  }
);

export const getProviderCredit = createAsyncThunk(
  "user/creditProvider",
  async (id, thunkAPI) => {
    try {
      const resp = await customFetch.get(`provider/credit`);
      return resp?.data;
    } catch (error) {
      return checkForUnauthorizedResponse(error, thunkAPI);
    }
  }
);
export const getProviderCreditTransactions = createAsyncThunk(
  "user/creditProviderTransacions",
  async (id, thunkAPI) => {
    try {
      const resp = await customFetch.get(`provider/credit/transactions`);
      return resp?.data;
    } catch (error) {
      return checkForUnauthorizedResponse(error, thunkAPI);
    }
  }
);
export const resetProviderPassword = createAsyncThunk(
  "user/resetProviderPassword",
  async (user, thunkAPI) => {
    try {
      const resp = await customFetch.post(
        "auth/provider/password/change",
        user
      );
      return resp?.data;
    } catch (error) {
      return checkForUnauthorizedResponse(error, thunkAPI);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logoutUser: (state) => {
      state.user = null;
      removeUserFromLocalStorage();
      state.user = null;
      window.location.href = "/auth/login";
    },
    returnChangePassword: (state) => {
      state.changePassword = false;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loginUser.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        toast.success("You are successfully logged in");
        state.user = payload;
        addUserToLocalStorage(payload);
        localStorage.removeItem("loginData");
        state.changePassword = false;
      })
      .addCase(loginUser.rejected, (state, { payload }) => {
        state.isLoading = false;
        if (payload?.message === "Kindly Proceed To Change Your Password") {
          state.changePassword = true;
          toast.loading(payload?.message);
          addItemToLocalStorage("loginData", { id: payload?.data });
        } else {
          state.changePassword = false;
          localStorage.removeItem("loginData");
          toast.error(payload?.message);
        }
        state.errMsg = payload;
      })
      .addCase(getProviderSubscribers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProviderSubscribers.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.subscriberList = payload;
      })
      .addCase(getProviderSubscribers.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errMsg = payload;
      })
      .addCase(resetProviderPassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(resetProviderPassword.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.passwordChanged = true;
        localStorage.removeItem("loginData");
        toast.success("Password changed successfully");
      })
      .addCase(resetProviderPassword.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload?.message);
      })
      .addCase(getProviderCredit.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProviderCredit.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.providerCredit = payload;
      })
      .addCase(getProviderCredit.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload?.message);
      })
      .addCase(getProviderCreditTransactions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        getProviderCreditTransactions.fulfilled,
        (state, { payload }) => {
          state.isLoading = false;
          state.transactions = payload;
        }
      )
      .addCase(getProviderCreditTransactions.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload?.message);
      });
  },
});

export const { logoutUser, returnChangePassword } = userSlice.actions;

export default userSlice.reducer;
