export const addUserToLocalStorage = (user) => {
  localStorage.setItem("userProvider", JSON.stringify(user));
};
export const removeUserFromLocalStorage = () => {
  localStorage.removeItem("userProvider");
};
export const getUserFromLocalStorage = () => {
  const result = localStorage.getItem("userProvider");
  const user = result ? JSON.parse(result) : null;
  return user;
};

export const addItemToLocalStorage = (key, newItem) => {
  // Retrieve existing data from local storage
  const existingData = localStorage.getItem(key);

  let newData = {};

  // Check if there is existing data
  if (existingData) {
    // Parse the existing data
    newData = JSON.parse(existingData);
  }
  newData = { name: newData, ...newItem };

  // Add the new item to the data

  // Store the updated data back to local storage
  localStorage.setItem(key, JSON.stringify(newData));
};
