import React, { useEffect, useState } from "react";
import ActiveBenefits from "../../components/ActiveBenefits";
import skeletonLoading from "../../assets/svgs/loading.svg";
import SearchAddContainer from "../../components/SearchAddContainer";
import ClientsTable from "../../components/table/ClientsTable";
import Pagination from "../../components/table/Pagination";
import { dateFormat, fmtCurrency, paginate } from "../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import {
  getProviderCredit,
  getProviderSubscribers,
} from "../../features/user/userSlice";
import Spinner from "../../components/PageLoader";
import { useNavigate } from "react-router-dom";
import MobileTable from "../../components/table/MobileTable";
import Icon from "../../components/Icon";

const Dashboard = () => {
  const [userSearch, setUserSearch] = useState("");
  const [paginatedList, setPaginatedList] = useState([]);
  const [paginatedMemberlist, setPaginatedMemberlist] = useState();
  const [page, setPage] = useState(0);
  const navigate = useNavigate();

  const { subscriberList, isLoading, providerCredit } = useSelector(
    (store) => store.user
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProviderSubscribers());
    dispatch(getProviderCredit());
  }, []);

  const memberListFilter = subscriberList?.filter((user) => {
    if (
      user?.firstName?.toLowerCase().includes(userSearch.toLowerCase()) ||
      user?.lastName?.toLowerCase().includes(userSearch.toLowerCase())
    ) {
      return user;
    }
  });

  useEffect(() => {
    if (paginatedMemberlist) {
      setPaginatedList(paginatedMemberlist[page]);
    }
  }, [paginatedMemberlist, page]);
  useEffect(() => {
    if (memberListFilter) {
      setPaginatedMemberlist(paginate(memberListFilter));
    }
  }, [memberListFilter?.length]);
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {subscriberList?.length >= 1 ? (
            <div className="overview">
              <div className="overview__header">
                <div className="wallet">
                  <div className="wallet__balance">
                    <p>
                      Wallet balance{" "}
                      <Icon id="eye-icon" width="16" height="15" />
                    </p>
                    <h2>{fmtCurrency(providerCredit?.balance)}</h2>
                  </div>
                  <div
                    className="wallet__btn"
                    onClick={() => {
                      navigate("/transactions");
                    }}
                  >
                    <p>
                      <Icon id="eye-icon" width="16" height="15" />
                      View history
                    </p>
                  </div>
                </div>
                {/* <div className="verification">
                  <div className="verification__balance">
                    <p>Client Code Verification</p>
                  </div>
                  <div className="verification__btn">
                    <Button
                      variant="secondary"
                      type="submit"
                      className="mt-40"
                      block
                      disabled={isLoading}
                    >
                      Scan code
                    </Button>
                    <Button
                      variant="primary"
                      type="submit"
                      className="mt-40"
                      block
                      disabled={isLoading}
                    >
                      Enter code
                    </Button>
                  </div>
                </div> */}
              </div>
              <div className="overview__container">
                <SearchAddContainer
                  searchInput={userSearch}
                  changeInput={(e) => setUserSearch(e.target.value)}
                />
              </div>
              <div className="overview__table">
                <div className="table-container">
                  <ClientsTable data={paginatedList} />

                  <div className="smaller__table">
                    {paginatedList?.map((user, index) => (
                      <MobileTable
                        key={index}
                        email={user?.email}
                        employeeId={user?.phone}
                        firstname={user?.firstName}
                        lastname={user?.lastName}
                        planName={user?.membershipPlan}
                        renewalDate={dateFormat(
                          user.expiryDate,
                          "MMM dd, yyyy"
                        )}
                        user={user}
                        threeDots={false}
                        status={user?.subscriptionStatus}
                      />
                    ))}
                  </div>
                  <Pagination
                    itemList={paginatedMemberlist}
                    page={page}
                    setPage={setPage}
                  />
                </div>
              </div>
            </div>
          ) : (
            <ActiveBenefits
              icon={skeletonLoading}
              text={`Your Flance Provider Portal is ready, but you haven't connected with any Flance users quite yet.`}
            />
          )}
        </>
      )}
    </>
  );
};

export default Dashboard;
