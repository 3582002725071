import React, { useEffect, useState } from "react";
import Icon from "../../components/Icon";
import { useDispatch, useSelector } from "react-redux";
import { dateFormat, fmtCurrency, paginate } from "../../utils/helper";
import {
  getProviderCredit,
  getProviderCreditTransactions,
} from "../../features/user/userSlice";
import Pagination from "../../components/table/Pagination";
import MobileTable from "../../components/table/MobileTable";
import TransactionsTable from "../../components/table/TransactionsTable";
import { useNavigate } from "react-router-dom";
import Spinner from "../../components/PageLoader";

const TransactionHistory = () => {
  const [paginatedList, setPaginatedList] = useState([]);
  const [paginatedTransactionlist, setPaginatedTransactionlist] = useState([]);
  const [page, setPage] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { providerCredit, isLoading, transactions } = useSelector(
    (store) => store.user
  );
  useEffect(() => {
    dispatch(getProviderCredit());
    dispatch(getProviderCreditTransactions());
  }, []);

  useEffect(() => {
    if (paginatedTransactionlist) {
      setPaginatedList(paginatedTransactionlist[page]);
    }
  }, [paginatedTransactionlist, page]);
  useEffect(() => {
    if (transactions) {
      setPaginatedTransactionlist(paginate(transactions));
    }
  }, [transactions?.length]);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <div className="overview">
            <p
              className="prev-btn"
              onClick={() => {
                navigate("/");
              }}
            >
              <Icon id="back" width="14" height="14" className="back-icon" />
              Back to Dashboard
            </p>
            <div className="overview__header">
              <div className="wallet">
                <div className="wallet__balance">
                  <p>
                    Wallet balance <Icon id="eye-icon" width="16" height="15" />
                  </p>
                  <h2>{fmtCurrency(providerCredit?.balance)}</h2>
                </div>
              </div>
            </div>
            <div className="overview__table">
              <div className="table-container">
                <TransactionsTable data={paginatedList} />

                <div className="smaller__table">
                  {paginatedList?.map((user, index) => (
                    <MobileTable
                      key={index}
                      email={dateFormat(user?.dateCreated, "MMM dd, yyyy")}
                      employeeId={user?.phone}
                      firstname={user?.zzzy}
                      lastname={user?.lastName}
                      planName={user?.membershipPlan}
                      renewalDate={dateFormat(user.expiryDate, "MMM dd, yyyy")}
                      user={user}
                      threeDots={false}
                      status={user?.subscriptionStatus}
                    />
                  ))}
                </div>
                <Pagination
                  itemList={paginatedTransactionlist}
                  page={page}
                  setPage={setPage}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default TransactionHistory;
