import React from "react";
import Table from "./Table";
import { dateFormat } from "../../utils/helper";

const ClientsTable = ({ data }) => {
  const columns = [
    "Client name",
    "Phone no.",
    "renewal date",
    "Membership plan",
    "Status",
  ];
  return (
    <Table columns={columns}>
      {data?.map((row, index) => (
        <tr className="pointer grey-bg" key={index}>
          <td>{`${row?.firstName} ${row?.lastName}`}</td>
          <td>{row?.phone}</td>
          <td> {dateFormat(row.expiryDate, "MMM dd, yyyy")}</td>
          <td>{row?.membershipPlan}</td>
          <td>
            <span
              className={`status ${
                row?.subscriptionStatus === "ACTIVE"
                  ? "employee-active"
                  : row?.subscriptionStatus === "EXPIRED" ||
                    row?.subscriptionStatus === "CANCELLED"
                  ? "status-inactive"
                  : "pending"
              }`}
            >
              {row?.subscriptionStatus}
            </span>
          </td>
        </tr>
      ))}
    </Table>
  );
};

export default ClientsTable;
