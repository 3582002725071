import { format } from "date-fns";
import PagesLogo from "../assets/svgs/PagesIcon.svg";
import TechPr from "../assets/images/TechPr-logo.png";
import Zedi from "../assets/svgs/Zedi.svg";
export const carouselDetails = [
  {
    id: 0,
    name: "Onyinye Bassey",
    position: "Head of Strategic Sourcing and Procurement",
    testimonial: `“Finding a gym near work, booking classes, and tracking my progress - it's all in the app. Super convenient!”`,
    companyLogo: PagesLogo,
    altText: "Pages by dami",
  },
  {
    id: 1,
    name: "Alex Johnson",
    position: "Head of People",
    testimonial: `“I really like your service and would refer my friends. You guys seem like you really care, I would also like to know when I can add my family to a plan.”`,
    companyLogo: TechPr,
    altText: "TechPr logo",
  },
  {
    id: 2,
    name: "Gerry Franklin",
    position: "Founder and CEO",
    testimonial: `“I really like your service and I like how your follow-ups. I will definitely refer Flance to my circle. Keep up the good work.”`,
    companyLogo: Zedi,
    altText: "Zedi logo",
  },
];

export const paginate = (employees, itemsPerPage = 7) => {
  const numberOfPages = Math.ceil(employees.length / itemsPerPage);

  const newFollowers = Array.from({ length: numberOfPages }, (_, index) => {
    const start = index * itemsPerPage;
    return employees.slice(start, start + itemsPerPage);
  });

  return newFollowers;
};

export const dateFormat = (date, type) => {
  if (!date) return "--";
  return format(new Date(date), type);
};

export const fmtCurrency = (value, currency) => {
  const formatter = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currencyDisplay: "symbol",
    currency: currency ? currency : "NGN",
  });
  return formatter.format(Number(value));
};
