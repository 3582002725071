export const navigations = [
  {
    id: 0,
    link: "/",
    icon: "person",
    coloredIcon: "blue-person",
    name: "Users",
    navName: "Members detail",
  },
];
