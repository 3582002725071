import React from "react";
import Icon from "../Icon";
import { useDispatch, useSelector } from "react-redux";
// import { resendInvitationLink } from '../../features/employees/employeesSlice';

const MobileTable = ({
  key,
  firstname,
  lastname,
  email,
  planName,
  // setDeactivateEmployeeModal,
  setCurrentEmployee,
  setDeleteEmployeeModal,
  currentEmployeeAction,
  handleEmployeeAction,
  employeeId,
  threeDots = false,
  nameCircle = true,
  renewalDate,
  user,
  handleClick,
  deleteIcon = false,
  handleDelete,
  status,
}) => {
  //   const { addEmployeeLoading } = useSelector((store) => store.employees);
  //   const dispatch = useDispatch();
  return (
    <div className="list-container" key={key}>
      <div className="table__head" onClick={handleClick}>
        <div className="topnav-right d-iflx al-i-c">
          {nameCircle && (
            <div className="avatar-circle table_circle">
              <div className="avatar-circle__placeholder iflx-center-center">
                <p className="avatar-circle__initials iflx-center-center">
                  {`${firstname?.charAt(0)}${lastname?.charAt(0)}`}
                </p>
              </div>
            </div>
          )}

          <div className="d-iflx al-i-c">
            <p className="mr-8">{`${firstname}  ${
              lastname ? lastname : ""
            }`}</p>
          </div>
        </div>
        {threeDots && (
          <span
            onMouseOver={() => handleEmployeeAction(employeeId)}
            onMouseLeave={() => handleEmployeeAction(null)}
          >
            <Icon width="4.5px" height="15.5px" id="three-dot" />
            {/* {currentEmployeeAction === employeeId && (
              <ul className="employee__table--dropdown">
                {status !== "ACTIVE" && (
                  <li
                    onClick={() =>
                      dispatch(
                        resendInvitationLink({
                          email: email,
                          callBackUrl: `${window.location.origin}/auth/employee-signup`,
                        })
                      )
                    }
                  >
                    <span className="">
                      {addEmployeeLoading ? "Sending..." : "Resend Invitation"}
                    </span>
                  </li>
                )}

                <li
                  onClick={() => {
                    setCurrentEmployee(user);
                    setDeleteEmployeeModal(true);
                  }}
                >
                  <span className="logoutBtn">Delete employee</span>
                </li>
              </ul>
            )} */}
          </span>
        )}
        {deleteIcon && (
          <span className="plan-table" onClick={handleDelete}>
            <Icon width="16px" height="19px" id="delete" />
          </span>
        )}
        {renewalDate && <span>{renewalDate}</span>}
      </div>

      <p>{email}</p>
      <p>{planName ? planName : "Nill"}</p>
    </div>
  );
};

export default MobileTable;
